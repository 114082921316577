<template>
  <div class="host">
    <section class="carousel-wrap">
      <Carousel :wrap-around="true">
        <Slide v-for="mainVisual in mainVisuals" :key="mainVisual.id">
          <a :href="mainVisual.link" target="_blank">
            <img class="carousel__item" :src="mainVisual.image_path" />
          </a>
        </Slide>
        <template #addons>
          <Navigation />
          <Pagination />
        </template>
      </Carousel>
    </section>

    <section class="mypage-title-image">
      <img
        src="https://mamab.jp/uploads/web_image/campaign-title.png"
        width="100%"
      />
    </section>
    <section class="mypage-full-banner">
      <ul>
        <li v-for="(fullBunner, index) in fullBunners" :key="fullBunner.id">
          <div class="bunner-flex">
            <div class="full-image">
              <img :src="fullBunner.image_path" />
            </div>
            <div class="full-text">
              <img
                :src="
                  'https://mamab.jp/uploads/web_image/mypage_no' +
                  (index + 1) +
                  '.png'
                "
              />
              <h2>{{ fullBunner.title }}</h2>
              <div class="company-area">
                <span
                  class="label"
                  :style="{
                    'background-color': fullBunner.color,
                  }"
                  >{{ fullBunner.name }}</span
                >
                {{ fullBunner.company_name }}
              </div>
            </div>
          </div>
          <div class="full-request">
            <a
              class="mypage-sponsor-link"
              :href="fullBunner.link"
              target="_blank"
            >
              <img src="https://mamab.jp/uploads/web_image/btn_ranking.png" />
            </a>
          </div>
        </li>
      </ul>
    </section>
    <section class="mypage-half-banner">
      <ul class="half-flex">
        <li v-for="halfBunner in halfBunners" :key="halfBunner.id">
          <div class="bunner-flex">
            <div class="half-image">
              <img :src="halfBunner.image_path" />
            </div>
          </div>
          <div class="half-description">
            <img
              v-if="halfBunner.is_special == 1"
              src="https://mamab.jp/uploads/web_image/present_ribbon.png"
              width="100%"
            />
            <h2>{{ halfBunner.title }}</h2>
            <div class="company-area">
              <span
                class="label"
                :style="{
                  'background-color': halfBunner.color,
                }"
                >{{ halfBunner.name }}</span
              >
              {{ halfBunner.company_name }}
            </div>
          </div>
          <div class="half-request">
            <a
              class="mypage-sponsor-link"
              :href="halfBunner.link"
              target="_blank"
            >
              <img
                src="https://mamab.jp/uploads/web_image/btn_0911539001648795720.png"
                style="90%"
              />
            </a>
          </div>
        </li>
        <li v-if="Object.keys(halfBunners).length % 2 == 1" class="no_sponsor">
          <img
            src="https://mamab.jp/uploads/web_image/karadanoto_logo.jpeg"
            width="80%"
          />
        </li>
      </ul>
    </section>
  </div>
</template>

<style lang="scss" scoped>
section.carousel-wrap {
  margin: 0 20px 0 20px;

  .carousel {
    .carousel__item {
      width: 100%;
      background-color: var(--carousel-color-primary);
      color: var(--carousel-color-white);
      font-size: 20px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .carousel__slide {
      width: 100% !important;
      height: auto;
    }

    .carousel__prev,
    .carousel__next {
      box-sizing: content-box;
      border: 5px solid white;
    }

    .carousel__pagination {
      padding: 0;
    }
  }
}

.mypage-title-image {
  margin-bottom: 20px;
}

ul {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

li {
  cursor: pointer;

  .bunner-flex {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
  }
}

h2 {
  margin-top: 10px;
  font-size: 1.85rem;
  line-height: 1.4;
  color: #464646;

  @media screen and (max-width: 480px) {
    font-size: 0.8rem;
  }
}

.label {
  display: inline-block;
  color: #fff;
  padding: 5px 1rem;
  margin-right: 4px;
  line-height: 1;

  @media screen and (max-width: 480px) {
    padding: 2px 0.4rem;
  }
}

.company-area {
  margin-top: auto;
  font-size: 1.3rem;
  color: #ccc;

  @media screen and (max-width: 480px) {
    font-size: 0.6rem;
  }
}

.mypage-full-banner {
  ul {
    li {
      &:nth-child(odd) {
        background: #f0f8ff;
      }

      width: 100%;
      padding: 10px 0;

      div {
        &.bunner-flex {
          padding-top: 10px;
          padding-bottom: 10px;
          display: flex;
        }

        .full-image {
          width: 28%;
          margin-left: 5%;
          margin-right: 2%;
        }

        .full-text {
          width: 60%;
          padding-right: 2.8%;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
        }
      }

      .full-request {
        padding-top: 10px;
        text-align: center;

        img {
          width: 90%;
        }
      }
    }
  }
}

.mypage-half-banner .half-flex {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #c0cdea;

  li {
    width: 50%;
    border: 1px solid #c0cdea;
    border-bottom: none;
    background: #f0f8ff;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;

    .bunner-flex {
      background: #fff;

      .half-image {
        margin-left: auto;
        width: 90%;

        img {
          width: 90%;
        }
      }
    }

    .half-description {
      display: flex;
      flex-direction: column;
      padding: 10px 5% 0;
      flex-grow: 1;

      h2 {
        margin-bottom: 8px;
        font-weight: normal;
      }
    }

    .half-request {
      text-align: center;
      padding-top: 10px;

      img {
        width: 90%;
      }
    }
  }
}
</style>

<script>
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default {
  name: "Mypage",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      mainVisuals: [],
      fullBunners: [],
      halfBunners: [],
    };
  },
  created() {
    this.axios
      .get("/mypage")
      .then((res) => {
        this.mainVisuals = res.data.mainVisuals;
        this.fullBunners = res.data.fullBunners;
        this.halfBunners = res.data.halfBunners;
      })
      .catch(() => {
        alert(
          "システムエラーのため、データの取得に失敗しました。お時間をあけて再度お試しください"
        );
      });
  },
};
</script>
